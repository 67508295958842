// extracted by mini-css-extract-plugin
export var checkbox = "summer-contest-form-module--checkbox--0d056";
export var checkboxCheckmark = "summer-contest-form-module--checkbox-checkmark--253c5";
export var commentContainer = "summer-contest-form-module--comment-container--0a94c";
export var errorBlink = "summer-contest-form-module--error-blink--bcf66";
export var fileContainer = "summer-contest-form-module--file-container--f1368";
export var fileInput = "summer-contest-form-module--file-input--d9d76";
export var fileSection = "summer-contest-form-module--file-section--78928";
export var flavourDescription = "summer-contest-form-module--flavour-description--4b8ca";
export var flavourSection = "summer-contest-form-module--flavour-section--32c9e";
export var input = "summer-contest-form-module--input--ee6ab";
export var inputContainer = "summer-contest-form-module--input-container--4fb81";
export var inputsSection = "summer-contest-form-module--inputs-section--afdc6";
export var phoneInput = "summer-contest-form-module--phone-input--25113";
export var question = "summer-contest-form-module--question--5e956";
export var questionContinued = "summer-contest-form-module--question-continued--c71da";
export var questionMain = "summer-contest-form-module--question-main--46207";
export var questionSection = "summer-contest-form-module--question-section--b4fb4";
export var questionSubtext = "summer-contest-form-module--question-subtext--79f3f";
export var radioContainer = "summer-contest-form-module--radio-container--7fd2c";
export var radioWrapper = "summer-contest-form-module--radio-wrapper--12298";
export var rulesContainer = "summer-contest-form-module--rules-container--b4124";
export var rulesSection = "summer-contest-form-module--rules-section--ef813";
export var select = "summer-contest-form-module--select--101b7";
export var submitButton = "summer-contest-form-module--submit-button--98265";
export var text = "summer-contest-form-module--text--b5bc6";
export var textSmall = "summer-contest-form-module--text-small--dcc84";
export var textarea = "summer-contest-form-module--textarea--534ff";
export var wordsCount = "summer-contest-form-module--words-count--8f849";