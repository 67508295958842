import React, { useRef } from 'react';
import {QueryClient, QueryClientProvider} from "react-query";
import { graphql } from 'gatsby';

import { layout, prize, form } from './summer-contest-page.module.scss';

import { IPage } from '../models/page.model';
import { IFlavour } from '../models/flavour';
import { getNodes } from '../utils/get-nodes';

import SummerContestLayout from "../layouts/summer-contest-layout";
import SummerContestPrize from '../components/organisms/summer-contest-prize';
import SummerContestForm from '../components/organisms/summer-contest-form';

interface ISummerContestPage {
    readonly data: {
        page: IPage | null;
        flavours: {
            edges: {
                node: IFlavour;
            }[];
        };
    };
}

const SummerContestPage: React.FC<ISummerContestPage> = ({ data }) => {
    const { page, flavours } = data;
    const containerRef = useRef<HTMLDivElement>(null);

    const bannerOnClick = () => {
        containerRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <SummerContestLayout className={layout} page={page} bannerOnClick={bannerOnClick}>
                <div ref={containerRef} className={prize}>
                    <SummerContestPrize />
                </div>
                <SummerContestForm className={form} flavours={getNodes(flavours)} />
            </SummerContestLayout>
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        flavours: allStrapiSummerFlavour(filter: { locale: { eq: $language } } sort: {fields: value}) {
            edges {
                node {
                    name
                    value
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, quality: 99, width: 125)
                            }
                        }
                        alternativeText
                    }
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default SummerContestPage;
