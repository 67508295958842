// extracted by mini-css-extract-plugin
export var block = "form-select-module--block--e4cdd";
export var blockIcon = "form-select-module--block-icon--68486";
export var blockText = "form-select-module--block-text--61bb5";
export var closeSelect = "form-select-module--close-select--914c3";
export var errorBlink = "form-select-module--error-blink--d6434";
export var input = "form-select-module--input--5187a";
export var inputBlock = "form-select-module--input-block--ecb48";
export var item = "form-select-module--item--942e5";
export var itemText = "form-select-module--item-text--bd227";
export var list = "form-select-module--list--d057e";
export var openSelect = "form-select-module--open-select--1334e";
export var select = "form-select-module--select--1378e";
export var selectActive = "form-select-module--select-active--80208";
export var selectError = "form-select-module--select-error--5cfda";
export var selectFilled = "form-select-module--select-filled--2207b";
export var selectOpen = "form-select-module--select-open--9ef83";