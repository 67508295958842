import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { ISummerForm } from '../models/summer-form.model';
import { object, string } from 'yup';

export const initialSummerContestFormValues: ISummerForm = {
    flavour: '',
    comment: '',
    files: [],
    firstname: '',
    lastname: '',
    email: '',
    phonePrefix: '+48',
    phone: '',
    size: '',
    rules1: false,
    rules2: false,
};

export const summerContestSizeOptions = [
    {
        id: 1,
        value: 's',
        displayName: 'S',
    },
    {
        id: 2,
        value: 'm',
        displayName: 'M',
    },
    {
        id: 3,
        value: 'l',
        displayName: 'L',
    },
    {
        id: 4,
        value: 'xl',
        displayName: 'XL',
    },
    {
        id: 5,
        value: 'xxl',
        displayName: 'XXL',
    }
]

// Tylko cyfry od 0 do 9, równo 9 cyfr
const phoneRegExp = /^[0-9]{9}$/;

export function summerContestFormSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape(
        {
            flavour: Yup.string().required(t('form.required.error')),
            comment: Yup.string()
                .max(500)
                .when('files', (files, schema) => {
                    return files && files.length > 0
                        ? schema.notRequired()
                        : schema.required(t('form.required.error'));
                }),
            files: Yup.array()
                .of(object({ content: string(), mimeType: string(), name: string() }))
                .nullable()
                .when('comment', (comment, schema) => {
                    return comment && comment.length > 0
                        ? schema.notRequired()
                        : schema.required(t('form.required.error'));
                }),
            firstname: Yup.string().required(t('form.required.error')),
            lastname: Yup.string().required(t('form.required.error')),
            email: Yup.string().email(t('form.email.error')).required(t('form.required.error')),
            phonePrefix: Yup.string().required(t('form.required.error')),
            phone: Yup.string().matches(phoneRegExp, t('form.phone.error')).required(t('form.required.error')),
            size: Yup.string().required(t('form.required.error')),
            rules1: Yup.boolean().oneOf([true], t('form.required.error')),
            rules2: Yup.boolean().oneOf([true], t('form.required.error')),
        },
        [['comment', 'files']]
    );
}
