// extracted by mini-css-extract-plugin
export var errorBlink = "summer-contest-prize-module--error-blink--7bc58";
export var info = "summer-contest-prize-module--info--f0777";
export var infoSecond = "summer-contest-prize-module--info-second--9d1f1";
export var link = "summer-contest-prize-module--link--e23b8";
export var prizeImages = "summer-contest-prize-module--prize-images--8aaae";
export var section = "summer-contest-prize-module--section--fe18b";
export var subtitle = "summer-contest-prize-module--subtitle--e985c";
export var textBig = "summer-contest-prize-module--text-big--90693";
export var textGold = "summer-contest-prize-module--text-gold--4e24e";
export var title = "summer-contest-prize-module--title--15d1e";