import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Field } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    radio,
    input,
    text,
    checkmark,
    image,
    imageRatio,
    textGold,
} from './radio-button-image.module.scss';

import RatioImage from './ratio-image';

interface IRadioButtonImageProps {
    className?: string;
    name: string;
    value: string;
    label?: string;
    imageData?: IGatsbyImageData;
    imageAlt?: string;
}

const RadioButtonImage: React.FC<IRadioButtonImageProps> = ({
    className = '',
    name,
    value,
    label,
    imageData,
    imageAlt,
}) => {
    const { t } = useI18next();

    return (
        <label className={`${radio} ${className}`}>
            <Field type="radio" name={name} className={input} value={value} />
            <span className={checkmark} />
            {imageData && (
                <RatioImage
                    className={image}
                    ratioClass={imageRatio}
                    image={imageData}
                    alt={imageAlt}
                    objectFit={'contain'}
                    draggable={false}
                    id={`radio-image-${value}`}
                />
            )}
            <p>
                <span className={`${textGold}`}>{t('summer.contest.form.bottle')}</span> <br />
                <span className={text}>{label || value}</span>
            </p>
        </label>
    );
};

export default RadioButtonImage;
