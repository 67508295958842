type GetTranslationKeyFunction = (...keys: string[]) => string;
export type GetTranslationKey = (...args: string[]) => GetTranslationKeyFunction;

const getTranslationKey: GetTranslationKey =
    (...args) =>
    (...keys) =>
        `${args.join('.')}.${keys.join('.')}`;

export const commonTranslations = getTranslationKey('common');

export default getTranslationKey;
