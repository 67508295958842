import React, { useEffect, useRef } from 'react';
import { Field, useField } from 'formik';

import {
    select,
    selectOpen,
    selectActive,
    selectFilled,
    block,
    blockText,
    blockIcon,
    list,
    item,
    itemText,
    selectError,
} from './form-select.module.scss';

import AngleIcon from '../../assets/images/svg/angle.svg';

import {IOption} from "../../models/option";

interface IFormSelect {
    name: string;
    options: IOption[];
    placeholder?: string;
    errorStyle?: boolean;
    formFieldName: string;
    onOptionClick?: () => void;
    className?: string;
}

const Select: React.FC<IFormSelect> = ({className = '', name, options, placeholder, errorStyle, onOptionClick, formFieldName}) => {
    const [open, setOpen] = React.useState(false);
    const [mainField, , mainHelpers] = useField(formFieldName);
    const setMainValue = useRef(mainHelpers.setValue);
    const [active, setActive] = React.useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const [label, setLabel] = React.useState(placeholder);

    useEffect(() => {
        const handleDropdownClose = (event: MouseEvent): void => {
            const select = selectRef?.current;
            if (!select) return;
            if (event.target === select || select.contains(event.target as Node | null)) return;
            setOpen(false);
        };

        if (open) {
            document.addEventListener('click', handleDropdownClose);
        }

        return () => {
            document.removeEventListener('click', handleDropdownClose);
        };
    }, [open]);

    const handleBlockClick = () => {
        setActive(true);
        setOpen((prevState) => !prevState);
    };

    const handleOptionClick = (index: number) => {
        return (event: React.MouseEvent<HTMLButtonElement>) => {
            if (!(event.target instanceof HTMLButtonElement)) return false;

            if (options) {
                mainHelpers.setValue(options[index].value, true);
                onOptionClick && onOptionClick();
            }
            setOpen(false);
        };
    };

    useEffect(() => {
        let foundOptions;

        if (options) {
            foundOptions = options.find((option) => option.value === mainField.value);
        }

        if (foundOptions) {
            setLabel(foundOptions.displayName ? foundOptions.displayName : foundOptions.value);
        } else {
            setMainValue.current('');
        }
    }, [mainField.value]);

    useEffect(() => {
        setMainValue.current = mainHelpers.setValue;
    }, [mainHelpers.setValue]);

    useEffect(() => {
        if (mainField.value === '') setLabel(placeholder);
    }, [placeholder, mainField.value]);

    return (
        <div
            className={`${select} ${open ? selectOpen + ' select__open' : ''}
                        ${label !== placeholder ? selectFilled : ''}
                        ${active ? selectActive : ''}
                        ${errorStyle ? selectError : ''}
                        ${className} select
                        `}
            ref={selectRef}
        >
            <>
                <Field type={'hidden'} id={name} name={name} />
                <button className={`${block} select__button`} type={'button'} onClick={handleBlockClick}>
                    <span className={blockText}>{label}</span>
                    <AngleIcon className={blockIcon} />
                </button>
            </>
            <div className={`${list} select__list`}>
                {options.map((option, index) => {
                    return (
                        <button
                            tabIndex={open ? 0 : -1}
                            className={item}
                            key={`select_${index}`}
                            onClick={handleOptionClick(index)}
                            data-value={option}
                            type={'button'}
                        >
                            <span key={`option-line-${option}`} className={itemText}>
                                {option.displayName ? option.displayName : option.value}
                            </span>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Select;