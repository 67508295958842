import React, { ReactElement } from 'react';
import { Field } from 'formik';

import {
    checkbox,
    input,
    checkmarkError,
    label,
    hint,
    checkmark,
    linkElement,
} from './form-checkbox.module.scss';
import FormHint from './form-hint';

interface IFormCheckbox {
    className?: string;
    type?: string;
    name: string;
    text: string | ReactElement;
    textContinued?: string | ReactElement;
    textLink?: string | ReactElement;
    link?: string;
    textClass?: string;
    hintText?: string | null;
    errorStyle?: boolean;
    checkmarkClass?: string;
}

const FormCheckbox: React.FC<IFormCheckbox> = ({
    type = 'checkbox',
    name,
    text,
    textContinued,
    textLink,
    link = '',
    className = '',
    textClass = '',
    hintText = '',
    errorStyle = false,
    checkmarkClass = '',
}) => {
    return (
        <label className={`${checkbox} ${className}`}>
            <Field type={type} name={name} className={input} />
            <span
                className={`${checkmark} ${errorStyle ? checkmarkError : ''} ${checkmarkClass}`}
            />
            <span className={`${label} ${textClass}`}>
                {text}
                {textLink && textContinued && (
                    <>
                        <a href={link} className={linkElement} target="_blank" rel="noreferrer">
                            {textLink}
                        </a>
                        {textContinued}
                    </>
                )}
            </span>
            {hintText && <FormHint className={hint} errorStyle={errorStyle} hintText={hintText} />}
        </label>
    );
};

export default FormCheckbox;
