import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import {
    section,
    prizeImages,
    info,
    title,
    subtitle,
    textGold,
    textBig,
    infoSecond,
    link,
} from './summer-contest-prize.module.scss';

const prizeMoney = '../../assets/images/summer-contest/money.png';
const prizeSpeaker = '../../assets/images/summer-contest/speaker.png';
const prizeTShirt = '../../assets/images/summer-contest/t-shirt.png';

import staticFiles from '../../config/static-files';

const SummerContestPrize = () => {
    const { t } = useI18next();

    return (
        <div className={`${section}`}>
            <h2 className={subtitle}>
                <span className={textGold}>{t('summer.contest.top.1')}</span>
                <span className={textBig}>{t('summer.contest.top.2')}</span>
            </h2>
            <div className={prizeImages}>
                <StaticImage
                    objectFit={'contain'}
                    objectPosition={'center'}
                    src={prizeMoney}
                    alt={t('summer.contest.prize.one.alt')}
                />
                <StaticImage
                    objectFit={'contain'}
                    objectPosition={'center'}
                    src={prizeSpeaker}
                    alt={t('summer.contest.prize.two.alt')}
                />
                <StaticImage
                    objectFit={'contain'}
                    objectPosition={'center'}
                    src={prizeTShirt}
                    alt={t('summer.contest.prize.three.alt')}
                />
            </div>
            <p className={info}>
                {t('summer.contest.info.1')}
                <span>{t('summer.contest.info.from')}</span>
                {t('summer.contest.info.to.word')}
                <span>{t('summer.contest.info.to')}</span>. {t('summer.contest.info.2')}{' '}
                <a
                    className={link}
                    href={staticFiles.summerContestRegulations}
                    target={'_blank'}
                    rel="noreferrer"
                >
                    {t('summer.contest.info.link')}
                </a>{' '}
                <span className={infoSecond}>{t('summer.contest.info.3')}</span>
            </p>
            <h1 className={title}>
                {t('summer.contest.info.vote')}
                <br />
                {t('summer.contest.info.vote.for')}
                <span className={textGold}>{t('summer.contest.info.vote.answer')}</span>
            </h1>
        </div>
    );
};

export default SummerContestPrize;
