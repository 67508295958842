import React, {
    ChangeEventHandler,
    FocusEventHandler,
    PropsWithChildren,
    useCallback,
} from 'react';

import { input, label } from './base-file-input.module.scss';

import {
    FileErrorEnum,
    getBase64,
    IBase64File,
    IValidateFileOptions,
    validateFiles,
} from '../../utils/file-utils';

import { isSettledFulfilled, isSettledRejected } from '../../utils/promise-utils';

export interface IUploadResult {
    uploadedFiles?: IBase64File[];
    uploadErrors?: string[];
}

export interface IBaseFileInputProps extends IValidateFileOptions {
    className?: string;
    inputClassName?: string;
    labelClassName?: string;
    name: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onClick?: () => void;
    onUploadStart?: () => void;
    onUploadFinish?: () => void;
    onChange?: (uploadResult: IUploadResult) => void;
    errorMessages?: Record<FileErrorEnum, string>;
    compressOptions?: Record<string, any>;
}

export default function BaseFileInput({
    className = '',
    inputClassName = '',
    labelClassName = '',
    children,
    fileTypes = [],
    maxFileCount = 1,
    maxFileSize = 5120000,
    name,
    onBlur,
    onClick,
    onUploadStart,
    onUploadFinish,
    onChange,
    errorMessages,
    compressOptions,
}: PropsWithChildren<IBaseFileInputProps>) {
    const handleChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
        if (typeof onUploadStart === 'function') {
            onUploadStart();
        }

        const files = Array.from(event.currentTarget.files || []);
        const { validFiles, validationErrors } = validateFiles(files, {
            fileTypes,
            maxFileSize,
            maxFileCount,
            errorMessages,
        });
        //@ts-ignore ?
        event.currentTarget.value = null;
        const uploadResult: IUploadResult = {};
        const data = await Promise.allSettled(
            validFiles.map((file) => getBase64(file, errorMessages, compressOptions))
        );
        uploadResult.uploadedFiles = [...data.filter(isSettledFulfilled).map((item) => item.value)];
        const readErrors = data.filter(isSettledRejected).map((item) => item.reason);
        uploadResult.uploadErrors = [...validationErrors, ...readErrors];

        if (typeof onChange === 'function') {
            onChange(uploadResult);
        }

        if (typeof onUploadFinish === 'function') {
            onUploadFinish();
        }
    };

    const handleClick = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick]);

    return (
        <div className={className}>
            <input
                className={`${input} ${inputClassName}`}
                type="file"
                id={name}
                name={name}
                {...(fileTypes.length > 0 ? { accept: fileTypes.join(',') } : {})}
                multiple={maxFileCount > 1}
                onChange={(event) => handleChange(event)}
                onClick={handleClick}
                {...(typeof onBlur === 'function' ? { onBlur: onBlur } : {})}
            />
            <label className={`${label} ${labelClassName}`} htmlFor={name}>
                {children}
            </label>
        </div>
    );
}
