import React from 'react';
import { IBase64File } from '../../utils/file-utils';

import { container, image, fileIcon, fileName } from './file-preview.module.scss';

import PdfIcon from './../../assets/images/svg/pdf.svg';
import VideoIcon from './../../assets/images/svg/video.svg';

interface IFilePreviewProps {
    file: IBase64File;
}

const FilePreview: React.FC<IFilePreviewProps> = ({ file }) => {
    if (typeof file.content !== 'string') return null;

    if (getFileType(file.mimeType) === 'image')
        return (
            <div className={container}>
                <img src={file.content} alt={file.name} className={image} />
            </div>
        );
    else {
        return (
            <div className={container}>
                {getFileType(file.mimeType) === 'video' && <VideoIcon className={fileIcon} />}
                {file.mimeType === 'application/pdf' && <PdfIcon className={fileIcon} />}
                <span className={fileName}>{file.name}</span>
            </div>
        );
    }
};

function getFileType(mimeType: string) {
    const [type] = mimeType.split('/');
    return type;
}

export default FilePreview;
