import React, { useRef, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import { useMutation } from 'react-query';
import { getImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    questionSection,
    question,
    questionMain,
    questionContinued,
    questionSubtext,
    fileSection,
    fileContainer,
    fileInput,
    text,
    textSmall,
    flavourDescription,
    radioWrapper,
    radioContainer,
    commentContainer,
    textarea,
    wordsCount,
    inputsSection,
    inputContainer,
    input,
    rulesSection,
    rulesContainer,
    checkbox,
    checkboxCheckmark,
    submitButton,
    flavourSection,
    select,
    phoneInput,
} from './summer-contest-form.module.scss';

import { summerContestFormMutation } from '../../api-ssr/summer-contest';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import pagesContext from '../../config/pages-context';
import staticFiles from '../../config/static-files';
import {
    summerContestFormSchema,
    initialSummerContestFormValues, summerContestSizeOptions,
} from '../../formik/summer-contest-form.config';

import { IFlavour } from '../../models/flavour';
import { ISummerForm } from "../../models/summer-form.model";

import Button from '../atoms/button';
import FormField from '../atoms/form-field';
import RadioButtonImage from '../atoms/radio-button-image';
import FileInput from '../atoms/file-input';
import FormCheckbox from '../atoms/form-checkbox';
import Select from "../atoms/select";
import HandleFormikChange from "../hoc/handle-formik-change";


interface ISummerContestFormProps {
    className: string;
    flavours: IFlavour[];
}

const MAX_FILE_SIZE_BYTES = 26214400;

const SummerContestForm: React.FC<ISummerContestFormProps> = ({ className, flavours }) => {
    const { t, language } = useI18next();
    const [buttonLoading, setButtonLoading] = useState(false);
    const formRef = useRef<FormikProps<ISummerForm>>(null);

    const mutation = useMutation(summerContestFormMutation, {
        onSuccess: (data) => {
            const resultsQuery = JSON.stringify(data.data).replace(/[" { }]/g, '');
            const resultsUrl =
                getPathFromSlug(pagesContext.summerContestThankYou.slug, language) +
                `?results=${resultsQuery}`;
            navigate(resultsUrl);
            setButtonLoading(false);
        },
        onError: () => {
            alert(t('summer.contest.mutation.error'));
            setButtonLoading(false);
        },
    });

   const handleFormikChange = (values: ISummerForm) => {
       if (values.phone.toString().length > 9) {
           formRef.current?.setFieldValue('phone',values.phone.toString().slice(0,9));
       }
   }

    return (
        <div className={`${className}`}>
            <Formik
                enableReinitialize={true}
                initialValues={initialSummerContestFormValues}
                validationSchema={summerContestFormSchema(t)}
                validateOnMount={true}
                innerRef={formRef}
                onSubmit={(values, actions) => {
                    setButtonLoading(true);

                    const selectedFlavour = flavours.find(
                        (flavour) => flavour.name === values.flavour
                    );
                    values = { ...values, flavour: selectedFlavour ? selectedFlavour.value : '' };
                    actions.setSubmitting(false);
                    mutation.mutate({ ...values });
                }}
            >
                {({ errors, touched, values }) => (
                    <Form>
                        <HandleFormikChange onChange={handleFormikChange} />
                        <div className={flavourSection}>
                            <p className={flavourDescription}>
                                {t('summer.contest.choose.flavour')}
                            </p>
                            <div className={radioWrapper}>
                                {flavours.map((flavour) => {
                                    return (
                                        <FormField
                                            className={radioContainer}
                                            id={'flavours'}
                                            errorStyle={!!(errors.flavour && touched.flavour)}
                                            key={`flavour-${flavour.name}`}
                                            hintText={
                                                errors.flavour && touched.flavour
                                                    ? errors.flavour
                                                    : ''
                                            }
                                        >
                                            <RadioButtonImage
                                                name={'flavour'}
                                                imageData={getImage(flavour.image.localFile)}
                                                value={flavour.name}
                                            />
                                        </FormField>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={questionSection}>
                            <p className={question}>
                                <span className={questionMain}>
                                    {t('summer.contest.form.question.main')}
                                </span>
                                <span className={questionContinued}>
                                    {t('summer.contest.form.question.continued')}
                                </span>
                            </p>
                            <p className={questionSubtext}>{t('summer.contest.form.subtext')}</p>
                            <FormField
                                id={'comment'}
                                errorStyle={!!(errors.comment && touched.comment)}
                                hintText={errors.comment && touched.comment ? errors.comment : ''}
                                className={commentContainer}
                            >
                                <Field
                                    as={'textarea'}
                                    type={'textarea'}
                                    className={textarea}
                                    name={'comment'}
                                    placeholder={t('summer.contest.form.comment.placeholder')}
                                    maxLength={500}
                                />
                            </FormField>
                            <p className={wordsCount}>{values.comment.length}/500</p>
                        </div>
                        <div className={fileSection}>
                            <p className={text}>{t('summer.contest.form.file.text.1')}</p>
                            <p className={textSmall}>{t('summer.contest.form.file.text.2')}</p>
                            <FormField
                                id={'file-field'}
                                errorStyle={!!(errors.files && touched.files)}
                                hintText={
                                    errors.files && touched.files ? (errors.files as string) : ''
                                }
                                className={fileContainer}
                            >
                                <FileInput
                                    maxFileSize={MAX_FILE_SIZE_BYTES}
                                    fileTypes={[
                                        'application/pdf',
                                        'image/jpg',
                                        'image/jpeg',
                                        'image/png',
                                        'video/x-msvideo',
                                        'video/mov',
                                        'video/mp4',
                                    ]}
                                    className={fileInput}
                                    name={'files'}
                                    placeholder={t('summer.contest.form.file.placeholder')}
                                    type={'short'}
                                />
                            </FormField>
                        </div>
                        <div className={inputsSection}>
                            <FormField
                                id={'first-name'}
                                errorStyle={!!(errors.firstname && touched.firstname)}
                                hintText={
                                    errors.firstname && touched.firstname ? errors.firstname : ''
                                }
                                className={inputContainer}
                            >
                                <Field
                                    className={input}
                                    name={'firstname'}
                                    placeholder={t('summer.contest.form.firstname.placeholder')}
                                />
                            </FormField>
                            <FormField
                                id={'last-name'}
                                errorStyle={!!(errors.lastname && touched.lastname)}
                                hintText={
                                    errors.lastname && touched.lastname ? errors.lastname : ''
                                }
                                className={inputContainer}
                            >
                                <Field
                                    className={input}
                                    name={'lastname'}
                                    placeholder={t('summer.contest.form.lastname.placeholder')}
                                />
                            </FormField>
                            <FormField
                                id={'email'}
                                errorStyle={!!(errors.email && touched.email)}
                                hintText={errors.email && touched.email ? errors.email : ''}
                                className={inputContainer}
                            >
                                <Field
                                    type={'email'}
                                    className={input}
                                    name={'email'}
                                    placeholder={t('summer.contest.form.email.placeholder')}
                                />
                            </FormField>
                            <FormField
                                id={'phone'}
                                hintText={errors.phone && touched.phone ? errors.phone : null}
                                errorStyle={!!(errors.phone && touched.phone)}
                                className={`${inputContainer} ${phoneInput}`}
                            >
                                <Field
                                    type={'number'}
                                    className={input}
                                    name={'phone'}
                                    placeholder={t('summer.contest.form.phone.placeholder')}
                                />
                            </FormField>
                            <FormField
                                id={'size'}
                                errorStyle={!!(errors.size && touched.size)}
                                hintText={errors.size && touched.size ? errors.size : ''}
                                className={inputContainer}
                            >
                                <Select className={select} name={'size'} options={summerContestSizeOptions} formFieldName={'size'} placeholder={t('summer.contest.form.size.placeholder')} />
                            </FormField>
                        </div>
                        <div className={rulesSection}>
                            <FormField
                                id={'rules1'}
                                errorStyle={!!(errors.rules1 && touched.rules1)}
                                hintText={errors.rules1 && touched.rules1 ? errors.rules1 : ''}
                                className={rulesContainer}
                            >
                                <FormCheckbox
                                    className={checkbox}
                                    name={'rules1'}
                                    text={t('summer.contest.form.rules1')}
                                    textLink={t('summer.contest.form.rules1.textLink')}
                                    link={staticFiles.summerContestRegulations}
                                    textContinued={t('summer.contest.form.rules1.textContinued')}
                                    checkmarkClass={checkboxCheckmark}
                                />
                            </FormField>
                            <FormField
                                id={'rules2'}
                                errorStyle={!!(errors.rules2 && touched.rules2)}
                                hintText={errors.rules2 && touched.rules2 ? errors.rules2 : ''}
                                className={rulesContainer}
                            >
                                <FormCheckbox
                                    className={checkbox}
                                    name={'rules2'}
                                    text={t('summer.contest.form.rules2')}
                                    checkmarkClass={checkboxCheckmark}
                                />
                            </FormField>
                        </div>
                        <Button type={'submit'} isLoading={buttonLoading} className={submitButton}>
                            {t('summer.contest.form.button.submit')}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SummerContestForm;
